<template>
    <div class="Client">
        <!-- BEGIN: Content-->
        <div class="app-content content ">
            <div class="content-overlay"></div>
            <div class="header-navbar-shadow"></div>
            <div class="content-wrapper container-xxl p-0">

                <banner title="Income Tax Return Filing" :breadcrumb="[
                    { label: 'Category ', url: 'http://localhost:8080/retailer/client' },
                    { label: 'Individual ' },
                
                ]">
                </banner>
                <div class="row" style="margin-top: -45px;">
                    <div class="col-xl-6 col-lg-6 col-md-6 col-12" style="padding:5px 5px 5px 5px ">
                          <div class="card">
                            <div class="card-body">
                                <h4><strong style="color:black;">Upload Doccuments</strong></h4>
                                <div class="progress ">
                                    <div style="background-color:#f21300;width: 20%" class="progress-bar"
                                        role="progressbar" aria-valuenow="40" aria-valuemin="40" aria-valuemax="100">20%
                                    </div>
                                </div>

                                <div class="row">
                                    <div class="col-xl-6 p-1" v-for="(docc,index) in allDoccs" :key="index">
                                        <label>{{docc.document}}</label>
                                        <input type="file" class="form-control" @change="uploadDocuments($event, docc)">
                                        <small v-if="docc.format">Format: {{docc.format}}</small><br>
                                        <small  v-if="docc.size">size: {{docc.size}} kb</small>
                                        <small v-if="docc.width">Format: {{docc.width}}px</small><br>
                                        <small  v-if="docc.height">size: {{docc.height}}px</small>
                                    </div>
                                </div>

                                <div class="row">
                                    <div class="col-12 p-1" v-for="(link, index) in  clientservicedocuments" :key="index">
                                        <!-- <span @click="downloadImg(link)">
                                            Download {{link.caption}}
                                          </span> -->

                                          <a :href="downloadImg(link)">  Download {{link.caption}}</a>
                                    </div>
                                </div>
                                
                                <div class="row">
                                    <div class="col text-end">
                                        <button type="button" @click="thankyouPage" class="btn btn-sm text-white shadow-lg " style="background-color:#00364f;">Done</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>


                    <div class="col-xl-6 col-lg-6 col-md-6 col-12" style="padding:5px 5px 5px 5px ">
                        <div class="card">
                            <div class="card-body">
                                <div class="row">
                                    <div class="col-12">
                                        <h2 class="heading">FAQ's</h2>
                                        <div class="accordion accordion-margin accordion-border" id="accordionMargin">
                                            <div class="accordion-item">
                                                <h2 class="accordion-header" id="headingMarginOne">
                                                    <button class="accordion-button collapsed" type="button"
                                                        data-bs-toggle="collapse" data-bs-target="#accordionMarginOne"
                                                        aria-expanded="false" aria-controls="accordionMarginOne">
                                                        <i data-feather='plus' class="accordian-plus"></i> What
                                                        is Salary Income?
                                                    </button>
                                                </h2>
                                                <div id="accordionMarginOne" class="accordion-collapse collapse"
                                                    aria-labelledby="headingMarginOne"
                                                    data-bs-parent="#accordionMargin">
                                                    <div class="accordion-body">
                                                        Pastry pudding cookie toffee bonbon jujubes jujubes
                                                        powder topping. Jelly beans gummi bears sweet roll
                                                        bonbon muffin liquorice. Wafer lollipop sesame snaps.
                                                        Brownie macaroon cookie muffin cupcake candy
                                                        caramels tiramisu. Oat cake chocolate cake sweet jelly-o
                                                        brownie biscuit marzipan. Jujubes donut
                                                        marzipan chocolate bar. Jujubes sugar plum jelly beans
                                                        tiramisu icing cheesecake.
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="accordion-item">
                                                <h2 class="accordion-header" id="headingMarginFour">
                                                    <button class="accordion-button collapsed" type="button"
                                                        data-bs-toggle="collapse" data-bs-target="#accordionMarginFour"
                                                        aria-expanded="false" aria-controls="accordionMarginFour">
                                                        <i data-feather='plus' class="accordian-plus"></i> What
                                                        is Business Income?
                                                    </button>
                                                </h2>
                                                <div id="accordionMarginFour" class="accordion-collapse collapse"
                                                    aria-labelledby="headingMarginFour"
                                                    data-bs-parent="#accordionMargin">
                                                    <div class="accordion-body">
                                                        Cheesecake muffin cupcake dragée lemon drops tiramisu
                                                        cake gummies chocolate cake. Marshmallow tart
                                                        croissant. Tart dessert tiramisu marzipan lollipop lemon
                                                        drops. Cake bonbon bonbon gummi bears topping
                                                        jelly beans brownie jujubes muffin. Donut croissant
                                                        jelly-o cake marzipan. Liquorice marzipan cookie
                                                        wafer tootsie roll. Tootsie roll sweet cupcake.
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="accordion-item">
                                                <h2 class="accordion-header" id="headingMarginFive">
                                                    <button class="accordion-button collapsed" type="button"
                                                        data-bs-toggle="collapse" data-bs-target="#accordionMarginFive"
                                                        aria-expanded="false" aria-controls="accordionMarginFive">
                                                        <i data-feather='plus' class="accordian-plus"></i> What
                                                        are Other Sources of Income?
                                                    </button>
                                                </h2>
                                                <div id="accordionMarginFive" class="accordion-collapse collapse"
                                                    aria-labelledby="headingMarginFive"
                                                    data-bs-parent="#accordionMargin">
                                                    <div class="accordion-body">
                                                        Cheesecake muffin cupcake dragée lemon drops tiramisu
                                                        cake gummies chocolate cake. Marshmallow tart
                                                        croissant. Tart dessert tiramisu marzipan lollipop lemon
                                                        drops. Cake bonbon bonbon gummi bears topping
                                                        jelly beans brownie jujubes muffin. Donut croissant
                                                        jelly-o cake marzipan. Liquorice marzipan cookie
                                                        wafer tootsie roll. Tootsie roll sweet cupcake.
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-12">
                                        <h2 class="heading">Videos</h2>
                                        <div class="video-player" id="plyr-video-player" style="margin: 10px;">
                                            <iframe src="https://www.youtube.com/embed/femaL6lGw1o" width="100%"
                                                height="360" allowfullscreen allow="autoplay"></iframe>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>






            </div>

        </div>
    </div>





</template>

<script>

// import DocumentUpload from '../../../../components/retailer/comman/DocumentUpload.vue'
import Banner from '../../../../components/retailer/comman/Banner.vue'

import Form from 'vform'
// import jQuery from "jquery";
// let $ = jQuery
export default {
    name: 'Invdividual4',
    components: {
        Banner,

    },
    data() {
        return {
            client_service_id: null,
            clientservicedocuments: [],
            allDoccs: [],
            uploadDocc_form: {
                client_service_id: null,
                service_document_id: null,
                alt: '',
                caption: '',
                serviceDocument: '',
                cwidth: '',
                cheight: '',
                cx: '',
                cy: '',
            },
            serviceCharge: '',
            charges: {},
            form: new Form({
                photo: '',
                pan: '',
                fname: '',
                lanme: '',
                phone: '',
                email: '',
                dob: null,
                gender: null,
                address: null,
                address2: null,
                alternativePhone: null,
                city: null,
                fatherName: '',
                pin: null,
                state_id: null,
                code: '',
            }),
          

        }
    },
    methods: {
//         downloadImage(url) {
//   fetch(url, {
//     mode : 'no-cors',
//   })
//     .then(response => response.blob())
//     .then(blob => {
//     let blobUrl = window.URL.createObjectURL(blob);
//     let a = document.createElement('a');
//     // a.download = url.replace("/^.*[\\\/]/", '');
//     a.href = blobUrl;
//     document.body.appendChild(a);
//     a.click();
//     a.remove();
//   })
// },
thankyouPage(){
    this.$router.push({ name: 'ThankYou' })
},
        downloadImg(link) {
             var url
        if (this.$store.state.imgUrl + '/clientservice/' + link.client_service_id + '/thumbs/' +link.document) {
             url = this.$store.state.imgUrl + '/clientservice/' + link.client_service_id + '/thumbs/' +link.document
            } 
            if (this.$store.state.imgUrl + '/clientservice/' + link.client_service_id + '/'  +link.document) {
                url = this.$store.state.imgUrl + '/clientservice/' + link.client_service_id + '/' +link.document
            } 
            // console.log('downloading : ', url); 
        return url;

    },
          clientserviceDocumentLoad(){
            this.$axios
        .get(`retailer/clientservicedocument?client_service_id=${this.client_service_id}`, { headers: { Authorization: 'Bearer ' + localStorage.accessToken } })
        .then(res => {
            // console.log('-------clientservice_id--------')
        //   console.log(res.data.data.data)
          this.clientservicedocuments = res.data.data.data
        })

        },
        uploadDocuments(event, docc){
            this.uploadDocc_form.serviceDocument = event.target.files['0']
            this.uploadDocc_form.service_document_id = docc.id
            this.uploadDocc_form.alt = docc.document
            this.uploadDocc_form.caption = docc.document
          
            // console.log('-------------------------------------------------------------------------------')
    
            const formData = new FormData()
            const keys = Object.keys(this.uploadDocc_form)
            for (let n = 0; n < keys.length; n += 1) {
                if (this.uploadDocc_form[keys[n]] !== null) {
                    formData.append(keys[n], this.uploadDocc_form[keys[n]])
                }
            }
            this.$axios
                .post('retailer/clientservicedocument', formData, { headers: { Authorization: 'Bearer ' + localStorage.accessToken } })
                .then(res => {
                    console.log(res.data)
                    // alert(res.data)
                    this.$swal.fire({
                        position: 'top-end',
                        icon: 'success',
                        title: 'The form was sent successfully.',
                        showConfirmButton: false,
                        timer: 1500
                    })
                    this.clientserviceDocumentLoad()
                })


        },
        LoadDocuments(){
            var itr = {}
            itr = JSON.parse(localStorage.getItem('itr'))
            var service_id
            service_id = itr.service_id
      this.$axios
        .get(`retailer/servicedocument?service_id=${service_id}`, { headers: { Authorization: 'Bearer ' + localStorage.accessToken } })
        .then(res => {
        //   console.log(res.data)
          this.allDoccs = res.data.data.data
        })

      
    },

      
    },
    created() {
        if (this.$route.meta.redirectIfLoggedIn == 'true' && localStorage.getItem('accessToken') == null) {
            this.$router.push({ name: 'Login' })
        } else {
            this.clientserviceDocumentLoad()
            this.LoadDocuments()
         
            var itr = {}
            itr = JSON.parse(localStorage.getItem('itr'))
            // if (itr.serviceCharge) {
                this.serviceCharge = itr.serviceCharge
                this.uploadDocc_form.client_service_id = itr.client_service_id
                this.client_service_id = itr.client_service_id
               
            // }
        }

    },
}
</script>

<style scoped>
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300&display=swap");

.accordian-plus {
    width: 20px;
    height: 20px;
    stroke: red;
    stroke-width: 4;
    stroke-linecap: round;
    stroke-linejoin: round;
    fill: none;
    font-family: "Poppins", sans-serif;
}

.p-text {
    color: #fff;
    /* font-family: poppins, Sans-serif; */
    font-size: 15px;
    font-weight: 500;
    text-align: center;
    font-family: "Poppins", sans-serif;
}

.bg-image {
    background-color: #fff;
    background-image: url(https://apk.mudhrape.com/wp-content/uploads/2022/01/background.png);
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    padding: 15px 0 120px;
    /* text-align: center; */
    color: white;
    /* background-size: 100% 100%;
   width: 100%; */
    font-family: "Poppins", sans-serif;
}

.h2-heading {
    color: white;
    font-family: "Poppins", sans-serif;
    font-size: 40px;
    font-weight: 600;
    text-align: center;
}

@media (max-width: 767px) {
    .h2-heading {
        font-size: 22px;
        font-family: "Poppins", sans-serif;
    }
}

.heading {
    color: #00364f;
    font-family: "Poppins", sans-serif;
    font-weight: 600;
    margin-bottom: 20px;
    padding: 20px 0;
}

.inner-card {
    box-shadow: 0px 0px 3px 0px rgb(0 0 0 / 50%);
    margin-top: 5px;
    margin-bottom: 5px;
    border-radius: 5px 5px 5px 5px;
    padding: 10px;
}

.heading-red {
    color: #f21300;
    font-family: "Poppins", sans-serif;
    font-size: 20px;
    font-weight: 600;
}
</style>